import { graphql, useStaticQuery } from 'gatsby';

export const useFetchAlertBanner = () => {
  const alertBannerQuery = useStaticQuery(graphql`
    query AlertBannerQuery {
      contentstackAlertBanner {
        id
        siteAlerts: site_alerts {
          isActive: is_active
          richTextEditor: rich_text_editor
          alertButton: alert_button {
            buttonText: button_text
            buttonType: button_type
            buttonIcon: button_icon
            buttonLink: button_link {
              openInNewWindow: open_in_new_window
              showExternalLinkPopup: show_external_link_popup
              linkUrl: link_url {
                href
              }
            }
          }
        }
      }
    }
  `);

  const siteAlerts = alertBannerQuery.contentstackAlertBanner.siteAlerts || {};

  return { siteAlerts };
};
