import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { ContextProviderComponent } from 'components/Context';
import { NEW_USER, RETURN_USER, AUTH_USER } from 'utils/constants';
import { LayoutProvider } from 'context/LayoutContext';
import tastyworksApi from 'utils/tastyworksApi';
import { dataLayerPush, isSameDate } from 'utils/functions';

/**
 * Note that this file must remain src/layouts/index.js
 * in order for the gatsby-plugin-layout plugin to work
 * properly.
 *
 * If it needs to change, then the config for that plugin
 * will need to be updated to point to the new file location.
 *
 * @see https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
 */
function WrappingLayout({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const visitorCookie = Cookies.get('visitorCookie');
  const newUserDateCookie = Cookies.get('newUserDateCookie');

  const [initialVisit, setInitialVisit] = useState(
    typeof visitorCookie === 'undefined' && !authenticated ? true : false
  );

  useEffect(() => {
    const listener = (event) => {
      const zvaElemId = 'zva-script';
      if (document.scrollingElement.scrollTop >=200 &&
          !document.getElementById(zvaElemId)) {

        let zvaScriptElem = document.createElement('script');
        zvaScriptElem.id = zvaElemId;
        zvaScriptElem.async = true;
        zvaScriptElem.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js';
        zvaScriptElem.setAttribute('data-apikey', '1SzzftE4Tx2wM3XUSkcleA');
        zvaScriptElem.setAttribute('data-env', 'us01');

        document.body.appendChild(zvaScriptElem);

        let updateChatWidgetElem = document.createElement('script');
        updateChatWidgetElem.src = '/updateChatWidget.js';
        document.body.appendChild(updateChatWidgetElem);
      }};
    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    }
  }, []);

  useEffect(() => {
    const authenticationCheck = async () => {
      const data = await tastyworksApi.validateSession();

      setAuthenticated(data);
    }

    authenticationCheck();
  }, [authenticated])

  useEffect(() => {
    if (initialVisit) {
      Cookies.set('newUserDateCookie', new Date(), { expires: 3650 })
      Cookies.set('visitorCookie', NEW_USER, { expires: 3650 });
      dataLayerPush('new_user', 'visitor_status');
    } else if (authenticated && visitorCookie !== AUTH_USER) {
      Cookies.set('visitorCookie', AUTH_USER, { expires: 3650 });
      dataLayerPush('authenticated_user', 'visitor_status');
    } else if (visitorCookie === RETURN_USER
        || visitorCookie === AUTH_USER
        || isSameDate(new Date(newUserDateCookie), new Date())
      ) {
      dataLayerPush('authenticated_user', 'visitor_status');
      return;
    } else {
      Cookies.set('visitorCookie', RETURN_USER, { expires: 3650 });
      dataLayerPush('return_user', 'visitor_status');
    }
  }, [authenticated, visitorCookie]);

  return (
    <ContextProviderComponent>
      <LayoutProvider>
        {children}
      </LayoutProvider>
    </ContextProviderComponent>
  );
}

export default WrappingLayout;
