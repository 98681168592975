import { HOMEPAGE_TITLE, PAGE_TITLE } from 'utils/constants';

export function checkButtonWidth(value) {
  if (value) {
    if (value === 100) {
      return 100 + '%';
    } else {
      return value + 'rem';
    }
  }

  return;
}

export function checkIsIOS() {
  if (typeof window !== "undefined" && typeof navigator !== "undefined") {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  return false;
};

export function calculateOffset(value) {
  if (value) {
    return 100 - value + '%';
  } else {
    return null;
  }
}

export function checkHeaderType(type = 'H2') {
  if (type === PAGE_TITLE) {
    return 'h2';
  } else if (type === HOMEPAGE_TITLE) {
    return 'h1';
  } else {
    return type.toLowerCase();
  }
}

export function buildHeader(header, type) {
  if (type === PAGE_TITLE) {
    const splitHeader = header.split('/');

    return splitHeader.join('<span>/</span>');
  } else if (type === HOMEPAGE_TITLE) {
    const splitHeader = header.split('/');
    const finalSplitHeader = [];
    splitHeader.forEach(item => {
      const headerText = '<span class="headline-text">' + item + '</span>';
      finalSplitHeader.push(headerText);
    });

    return finalSplitHeader.join('<span>/</span>');
  } else {
    return header;
  }
}

export function buildBasicRows(columns) {
  if (columns.length < 1) return [];

  // Get the number of rows and assume all columns have same number of rows
  const numRows = columns[0]?.rows?.length;

  // Build empty array based on number of rows
  let emptyRows = [];
  for (let i = 0; i < numRows; i++) {
    emptyRows.push([]);
  }

  // Build out html rows to number of columns
  const htmlRows = columns.reduce((rows, column) => {
    for (let i = 0; i < column.rows.length; i++) {
      rows?.[i]?.push(column.rows[i]);
    }
    return rows;
  }, emptyRows);

  return htmlRows;
}

export function buildComparisonRows(columns = [], headers = []) {
  const firstHeader = headers[0];
  const firstColumn = columns[0];

  const comparisonRows = [];
  columns?.slice(1)?.forEach((column, index) => {
    const { rows = [] } = column;

    comparisonRows.push({
      name: firstHeader.content,
      data: headers.slice(1)[index].content,
      header: true,
    });

    rows?.forEach((row, index) => {
      const title = firstColumn?.rows[index]?.content?.replace(
        /<[^>]*>?/gm,
        ''
      );
      const formattedContent = row?.content?.replace(/<[^>]*>?/gm, '');

      comparisonRows.push({ name: title, data: formattedContent });
    });
  });

  return comparisonRows;
}

export function buildDynamicComparisonRows(columns = [], headers = []) {
  if (columns.length < 1 || headers.length < 1) return [];

  const comparisonRows = [];
  columns[0].rows.forEach((_, rowIndex) => {
    if (columns[0].rows[rowIndex].rowSubheader) {
      comparisonRows.push({
        name: columns[0].rows[rowIndex].content,
        header: true,
        newTable: true,
        background: headers[0].backgroundColor,
      });
    } else {
      // For each row...
      // Add the subheader row with what's being compared
      comparisonRows.push({
        name: columns[0].rows[rowIndex].content,
        subheader: true,
        color: columns[0].rows[rowIndex].contentColor,
      });

      // Add the value for each option being compared
      columns?.slice(1)?.forEach((column, columnIndex) => {
        const contentData = headers.slice(1)[columnIndex]?.content;
        const brokerData = headers.slice(1)[columnIndex]?.broker?.[0]?.title;
        const row = column.rows[rowIndex];

        comparisonRows.push({
          name: brokerData ? brokerData : contentData,
          data: row.content,
          color: row.contentColor,
          subheader: false,
        });
      });
    }
  });

  return comparisonRows;
}

// allow for proper escaping of html elements when building json-ld
export function getRawText(text) {
  let rawText = text.replace(/<[^>]*>/g, ' '); //Replaces <[^>]*> with an empty string
  rawText = rawText.replace(/\s+/g, ' '); //Replaces \s+ with an empty string
  rawText = rawText.replace(/&nbsp;/g, ' '); //Replaces &nbsp; with a space
  rawText = rawText.replace(/&#x27;/g, "'"); //Replaces &#x27; with apostrophe
  rawText = rawText.trim();
  return rawText;
}

export function autoOptimizeImage(url, optimizeSize = false, width) {
  if (url) {
    let optimizedImage = `${url}?format=pjpg&auto=webp`;

    if (optimizeSize && width) {
      optimizedImage += `&width=${width}`;
    }

    return optimizedImage;
  }

  return url;
}

export function optimizeImageHeight(url, height) {
  if (url) {
    let optimizedImage = `${url}?format=pjpg&auto=webp`;

    if (height) {
      optimizedImage += `&height=${height}&disable=upscale`;
    }

    return optimizedImage;
  }

  return url;
}

// This function is expecting date params to be passed in that are using
// the native JavaScript Date() object
export function isSameDate(day1, day2) {
  return (
    day1.getUTCFullYear() === day2.getUTCFullYear() &&
    day1.getUTCMonth() === day2.getUTCMonth() &&
    day1.getUTCDate() === day2.getUTCDate()
  );
}

export function isTouchEnabled() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export const calcPercent = (value1, value2) => {
  const percent = (value1 / value2) * 100;
  return Math.round(percent);
};

export const createHeaderId = string => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export function dataLayerPush(message, dataKey = 'event') {
  window.dataLayer?.push({ [dataKey]: `${message}` });
}

export const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
    } else {
        content = [content, 'maximum-scale=1.0'].join(', ')
    }

    el.setAttribute('content', content);
  }
};

export const injectCSS = css => {
  let el = document.createElement('style');
  el.type = 'text/css';
  el.innerText = css;
  document.head.appendChild(el);
  return el;
};
